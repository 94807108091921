<template>
	<div class="page">
		<fieldset class="search-box">
			<legend>查询条件</legend>
			<el-input size="small" style="width: 300px;margin-right: 20px;" placeholder="请输入设备号" v-model="deviceSn">
				<template #prepend>设备号</template>
			</el-input>
			<el-button size="small" @click="search">搜索</el-button>
		</fieldset>

		<div ref="tableBox" style="margin-top: 10px;flex-grow: 1; background-color: white;padding-bottom: 20px;">
			<el-table element-loading-svg-view-box="-10, -10, 50, 50" element-loading-background="rgba(0, 0, 0, 0.8)"
				element-loading-text="加载中" v-loading="loading" size="small" header-cell-class-name="tableHeader"
				:data="operateList" border :max-height="tableHeight" style="width: 100%;">
				<el-table-column fixed="left" prop="deviceSn" label="设备号" width="150">
				</el-table-column>
				<el-table-column label="操作人员" width="250">
					<template #default="scope">
						<div>{{scope.row.user.userNick}}</div>
					</template>
				</el-table-column>
				<el-table-column prop="createTime" label="操作时间" width="220">
				</el-table-column>
				<el-table-column prop="remark" label="操作内容">
				</el-table-column>
			</el-table>

			<div style="margin-top: 20px; float: right;">
				<el-pagination v-model:currentPage="currentPage" :page-sizes="[30]" :page-size="30"
					layout="total, sizes, prev, pager, next, jumper" :total="count" @size-change="handleSizeChange"
					@current-change="handleCurrentChange">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		selectList,
	} from '../../api/operate.js'
	export default {
		data() {
			return {
				start:0,
				limit:30,
				operateList:[],
				count:0
			}
		},

		mounted() {
			this.tableInit()
			this.getList()
		},

		methods: {
			//表格高度自适应
			tableInit: function() {
				var that = this
				let tableBox = this.$refs.tableBox
				this.tableHeight = tableBox.offsetHeight - 60
				setTimeout(function() {
					tableBox.children[0].style.maxHeight = that.tableHeight + "px"
				}, 0)
			},
			
			getList:function(){
				var that = this
				selectList({
					start:that.start,
					limit:that.limit
				}).then(res=>{
					that.operateList = res.data.data.list
					that.count = res.data.data.count
				})
			}
		}
	}
</script>

<style scoped="scoped">
	.page {
		background-color: white;
		padding: 10px;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		height: 100%;
	}

	.search-box {
		margin: 0;
		border: 1px solid #e6e6e6;
		padding: 10px 20px 20px 20px;
		color: #6b6b6b;
	}
</style>
